import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const LazyComponent = (importFn: () => Promise<{ readonly default: () => JSX.Element }>) => {
    const Component = lazy(importFn);
    return <Component/>
}

function Router() {
    return <BrowserRouter>
        <Suspense>
            <Routes>
                <Route path={'watch'} element={LazyComponent(() => import('pages/Watch'))}/>
                <Route path={'registerId'} element={LazyComponent(() => import('pages/registerId'))}/>
                <Route path={'tempadmin'} element={LazyComponent(() => import('pages/tempadmin'))}/>
            </Routes>
        </Suspense>
    </BrowserRouter>;
}

export default Router;
